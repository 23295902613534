<template>
  <div>
    <v-app-bar relative color="#1e1e2d" dark>
      <v-app-bar-nav-icon
        class="navbar-drawer"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <img style="width: 100px" src="@/assets/Logo_HollyAff.png" alt="" />
      <Clock />

      <v-spacer></v-spacer>
      <!-- <v-app-bar-title class="ml-4">{{ pageName }}</v-app-bar-title> -->
      <v-list
        color="transparent"
        dense
        class="p-0 d-flex align-items-center top-header-navbar"
        style="height: 35px; padding: 0"
      >
        <v-list-item-group
          v-model="selectedItem"
          color="grey lighten-4"
          class="d-flex"
        >
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :link="true"
            :to="item.url"
            class="mb-0"
          >
            <!-- @click="pageName = item.title" -->
            <v-list-item-content>
              <div class="header-item-link">
                <v-icon v-text="item.icon"></v-icon>
                {{ item.title }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary color="#1e1e2d">
      <div class="d-flex align-center justify-center mt-4">
        <img style="width: 100px" src="@/assets/Logo_HollyAff.png" alt="" />
      </div>
      <v-list nav dense flat>
        <v-list-item-group active-class="active-nav-item">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :link="true"
            :to="item.url"
            class="mb-0 nav-item"
          >
            <!-- @click="pageName = item.title" -->

            <template>
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Clock from "../components/ClockComponent.vue";
export default {
  name: "HeaderComponent",
  props: { toggleDrawer: Function },
  components: {
    Clock,
  },
  data: () => ({
    selectedItem: 1,
    // pageName: "",
    drawer: false,
  }),
  // mounted() {
  //   this.changePageName();
  // },
  methods: {
    // changePageName() {
    //   this.pageName = [...this.items].find(
    //     (item) => item.url === this.$route.path
    //   )
    //     ? [...this.items].find((item) => item.url === this.$route.path).title
    //     : "";
    // },
  },
  // watch: {
  //   "$route.fullPath": function (newPath, oldPath) {
  //     this.changePageName();
  //   },
  // },
  computed: {
    ...mapState({
      settings: (state) => state.layout.header,
    }),
    items: () => [
      {
        title: "Contacts",
        icon: "mdi-account-group",
        url: "/contacts",
      },
      {
        title: "Broadcasts",
        icon: "mdi-bullhorn-outline",
        url: "/broadcasts",
      },
      { title: "Domains", icon: "mdi-web", url: "/domains" },
      {
        title: "Imported files",
        url: "/imported-files",
        icon: "mdi-file-import",
      },
      {
        title: "Exported files",
        url: "/exported-files",
        icon: "mdi-file-export",
      },
    ],
  },
};
</script>

<style scoped lang="scss">
.top-header-navbar {
  .v-list-item {
    font-size: 12px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    .header-item-link {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 17px;
        margin-right: 6px;
      }
    }
  }
  .v-list-item--link:before {
    border-radius: 8px;
  }
}
.v-list--dense .v-list-item {
  .v-list-item__icon {
    margin-right: 8px;
  }
  .v-badge {
    top: -8px;
    right: 10px;
  }
}

.nav-item {
  color: #9899ac !important;
  padding: 6px;
  i {
    color: #9899ac !important;
  }
}

.active-nav-item {
  color: white !important;
  background-color: #1b1b28 !important;
  i {
    color: white !important;
  }
}

.navbar-drawer {
  display: none;
}

@media (max-width: 991px) {
  .navbar-drawer {
    display: block;
  }
  .top-header-navbar {
    display: none !important;
  }
}
</style>

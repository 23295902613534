<template>
  <div class="clock">
    Server Time
    <span class="hour">{{ hours }}</span
    >:<span class="minute">{{ minutes }}</span> UTC
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      hours: "",
      minutes: "",
    };
  },
  mounted() {
    this.updateTime();
    setInterval(this.updateTime, 1000); // Оновлюємо час кожну секунду
  },
  methods: {
    updateTime() {
      const now = moment.utc();
      this.hours = now.format("HH");
      this.minutes = now.format("mm");
    },
  },
};
</script>

<style>
.clock {
  font-size: 13px;
  padding: 2px 12px;
  border: 1px solid;
  border-radius: 12px;
  margin-left: 12px;
}
</style>
